.list__about > li {
  margin-left: 4ch;
}
.list__about > li:first-child,
.list__contact > li:first-child {
  margin-top: 0.5rem;
}

.list__contact {
  list-style: none;
}
.list__contact > li {
  margin-left: 0.5ch;
}
.mailto:link,
.mailto:visited {
  color: var(--primary-text-color);
}
