.custom_input {
  display: inline;
}

/* HELPERS */
.flex {
  display: flex;
}
.flex_column {
  display: flex;
  flex-direction: column;
}
.flex_row {
  display: flex;
  flex-direction: row;
}
.flex_wrap {
  flex-wrap: wrap;
}
.justify_center {
  justify-content: center;
}
.justify_space_between {
  justify-content: space-between;
}
.align_center {
  align-items: center;
}
.align_flex_start {
  align-items: flex-start;
}
.align_flex_end {
  align-items: flex-end;
}
/* HELPERS */

/* Currency */
.currency_box {
  display: flex;
  position: fixed;
  z-index: 99;
  right: 1rem;
  bottom: 0.1rem;
}
.currency_box select {
  padding: 0.4rem 1rem;
  border-radius: 3px;
  background-color: var(--primary-color);
  color: var(--primary-white-color);
  border: none;
  box-shadow: -2px -2px 7px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.currency_box select option {
  background-color: var(--primary-white-color);
  color: var(--primary-color);
  padding: 0.4rem 0.1rem;
}
/* Currency */

.subscribe_input[type="email"] {
  border: 2px solid var(--color-3);
  padding: 0.8rem 1rem;
  color: var(--primary-text-color);
}
.subscribe_input[type="email"]:invalid {
  border-color: var(--primary-color);
}

@media screen and (max-width: 450px) {
  .subscribe_input[type="email"] {
    margin-bottom: 1rem;
    width: 100%;
  }
}
