:root {
  --primary-color: #ca5e2c;
  --secondary-color: #ac9570;

  --primary-hover-color: #742b0a;
  --secondary-hover-color: #7c642f;

  --primary-text-color: #333;

  --primary-white-color: #fff;
  --primary-background-color: #fafafa;

  /* Components Background Colors */

  --input-bg-color: #eee;

  /* Components Text Colors */

  /* Components Hover Colors */
  --navlink-hover-color: #333;
  --navlink-bg-hover-color: #fafafa;

  /* Components Active Colors */
  --navlink-bg-active-color: #a8987f;
  /* CATERGORIES COLOR */
  --trending-color: #da6f18;
  --new-color: #55c9d8;
  --top-color: #91dd39;

  /* ORDERS */
  --color-1: #333;
  --color-2: #4d91b1;
  --color-3: #e0d2d2;
  --color-4: #ada393;
  --color-5: #555;
  --color-6: #888;
  --shadow-color: #ddd;
  --not-fullfilled: #e0d2d2;
  --fullfilled: #a4e2a4;
  --cancelled: #e25f07;

  /* Navlink Category */
  --category-nav-hover-color: #999;

  /* Menu Overlay */
  --menu-overlay-color: rgba(0, 0, 0, 0.9);

  /* Sections */
  --section-bg-color: #e0d2d2;

  /* V2 */
  --p1: #f3f4f2;
  --p2: #030303;
  --p3: #efd4ba;
  --p4: #abcdab;
  --p5: #434237;
  --p6: #a0a39f;

  --color-green: #eefcef;
  --color-orange: #ffeee2;
  --color-blue: #e6f5fa;
  --color-purple: #f4f5f9;
  --color-brown: #f9f8f3;
  --color-white: #ffffff;
  --color-black: #000000;

  --error: #B20D30;
  /* --error: #DF2935; */
  --info: #4d808a;
  --warning: #EEBA0B;

  /* Properties sizes */
  --border-radius: .5rem;

  /* Theme-1 */
  --black: var(--p2);
  --light: var(--p1);

  --text-color: var(--p2);
  --label-color: var(--p5);

  --btn-border-color: var(--p2);

  --bg-color-light: var(--p1);
  
  --danger-color: var(--error);
  --danger-text-color: var(--color-white);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}
html,
body {
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;

  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
