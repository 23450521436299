ion-icon {
  pointer-events: none;
}
.icon {
  cursor: pointer;
}
.logo {
  position: absolute;
  left: 50%;
  top: 0.8rem;
  transform: translateX(-50%);
}
.nav_links {
  display: flex;
}
.nav_links ion-icon,
.search ion-icon {
  font-size: 1.8rem;
  cursor: pointer;
}
.close {
  cursor: pointer;
  position: absolute;
  right: 0.8rem;
}
.close ion-icon {
  font-size: 3rem;
  color: var(--color-4);
}
.greeting {
  position: absolute;
  left: 1.3rem;
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
}
.close ion-icon:hover {
  color: var(--primary-white-color);
}
.tag1,
.tag2 {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.8rem;
  border-radius: 50%;
  line-height: 1.4;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
  text-align: center;
}
.tag1 {
  top: 0.25rem;
  transform: translate(-50%, -50%);
  background-color: var(--primary-text-color);
  color: var(--primary-white-color);
}
.tag2 {
  top: 35%;
  transform: translate(-35%, -50%);
  background-color: var(--color-3);
}
.nav_links a:link,
.nav_links > ion-icon {
  margin-right: 1rem;
}

.category_links a:link,
.category_links a:visited {
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.4rem 0.8rem;
  color: var(--primary-text-color);
}

.category_links a:not(:last-child) {
  margin-right: 2rem;
}
.category_links a:hover,
.category_links a:active {
  color: var(--category-nav-hover-color);
}

@media screen and (max-width: 768px) {
  .category_links {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 95vh;
    margin-top: 3rem;
    overflow-y: auto;
  }
  .category_links a:link,
  .category_links a:visited {
    margin: 0.1rem 0;
    font-weight: 500;
    min-width: 100%;
    padding: 1.8rem 1rem;
    background-color: var(--color-4);
    text-align: center;
  }
  .category_links a:hover,
  .category_links a:active {
    color: var(--primary-white-color);
    background-color: #a8987f;
  }
  .category_links a:last-child {
    margin-bottom: 2rem;
  }
  .category_links ion-icon {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .logged_nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    align-items: flex-start;
    justify-content: space-between;
  }
  .logged_nav > a {
    min-width: 80px !important;
  }
}

@media screen and (max-width: 484px) {
  .logo {
    all: unset;
  }
  .logo h1 {
    font-size: 1.5rem;
  }
}
