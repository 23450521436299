.link:link,
.link:visited {
  text-decoration: none;
}
.button {
  all: unset;
  padding: 0.4rem 0.8rem;
  min-width: 10rem;
  cursor: pointer;
  text-align: center;
  line-height: 1.3;
  color: #333;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;

  transition: all 0.3s ease-in-out;
}
.ripple {
  background-position: center;
  transition: background 0.5s;
}
.ripple:hover {
  background: #555 radial-gradient(circle, transparent 1%, #555 1%)
    center/15000%;
  color: #fff;
}
.ripple:active {
  background-color: #888;
  background-size: 100%;
  transition: background 0s;
}
/* Helper */
.primaryBtn {
  background: var(--primary-color);
  color: #fff;
}
.primaryBtn:hover {
  background: var(--primary-hover-color)
    radial-gradient(circle, transparent 1%, var(--primary-color) 1%)
    center/15000%;
}
.primaryBtn:active {
  background-color: var(--primary-color);
  background-size: 100%;
  transition: background 0s;
}
.secondaryBtn {
  background: var(--secondary-color);
  color: #fff;
}
.secondaryBtn:hover {
  background: var(--secondary-hover-color)
    radial-gradient(circle, transparent 1%, var(--secondary-color) 1%)
    center/15000%;
}
.secondaryBtn:active {
  background-color: var(--secondary-color);
  background-size: 100%;
  transition: background 0s;
}
.blackBtn {
  background-color: #333;
  color: #fff;
}
.whiteBtn {
  background-color: #fff;
  color: #333;
}
.disabledBtn {
  pointer-events: none;
  background: #999;
}
.disabledBtn:hover {
  background: #555;
}
.removeShadow {
  box-shadow: none;
}
.activeBtn {
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3) !important;
}
