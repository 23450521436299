.loader_box {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex: 1 1 100%;
  min-height: 30vh;
}
.loader {
  display: block;
  height: 4.5rem;
  width: 4.5rem;
  border: 0.5rem solid transparent;

  border-left-color: var(--color-1);
  border-right-color: var(--color-4);

  border-radius: 5rem;

  animation: loader 0.75s ease-in-out infinite forwards;
}

@keyframes loader {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
