.form-demo .card {
  min-width: 450px;
}
.form-demo .card form {
  margin-top: 2rem;
}
.form-demo .card .field {
  margin-bottom: 2rem;
}
.field-checkbox {
  margin-bottom: 2rem;
}
.text-center {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--primary-text-color);
}
@media screen and (max-width: 960px) {
  .form-demo .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-demo .card {
    width: 100%;
    min-width: 0;
  }
}
